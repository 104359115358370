@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,500;1,600&display=swap");


html,
body,
#root,
#root > * {
height: 100%;

}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 14px;
    font-family: 'Poppins';
}

a {
    /*text-decoration: underline;*/
    cursor: pointer;
}

#searchForm > .ant-row > .ant-row.ant-form-item {
    width: 100%;
    text-align: right;
    padding-right: 12px;

}
.ant-col {
    /*flex: 1;*/
    max-width: 100%;
    width: 100%;

}
.ant-btn:first-child {
     /*margin-right: 10px;*/
 }
.layout-page-sider{
    overflow-y: auto;
}
.app-loading-wrapper.ant-spin-spinning {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(256, 256, 256, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo{
    height: 64px;
    width: 200px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}
.layout-page-header{
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    box-shadow: 0 4px 10px #2f2f2f;
}
.layout-page-header-light{
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    box-shadow: 0 4px 10px #dddddd;
}
.layout-page-header-main{
    padding: 0 15px;
    flex: 1;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}
.layout-page .actions {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layout-page .actions > *{
    margin-left: 30px;
    height: 100%;
    display: flex;
    align-items: center;
}
.sidebar-trigger{
    width: 20px !important;
}

.layout-page-content {
    flex: auto;
    overflow: auto;
    padding: 6px;
    box-sizing: border-box;
}
.MainContent {
    /*overflow: auto !important;*/
}
.ant-menu .ant-menu-item{
    /*white-space: unset !important;*/
}
#pageTabs-actions{
    margin-right: 10px;
}
.mainPage{
    padding: 16px 6px 6px 6px;
    flex: auto;
    overflow: auto;
    box-sizing: border-box;
}
/*.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab{*/
    /*border: 1px solid rgba(5, 5, 5, 0.06);*/
/*}*/
.editable-cell-value-wrap{
    padding: 4px 11px;
    border: 1px solid transparent;
    border-radius: 2px;
}
.editable-cell-value-wrap:hover{
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
}
/*input:-webkit-autofill {*/
/*    -webkit-box-shadow: 0 0 0 1000px white inset !important; !* Change 'white' to your desired background color *!*/
/*    -webkit-text-fill-color: inherit !important;*/
/*}*/

.config_tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    /*justify-content: space-between;*/
}

.custom-search-input .ant-input-search-button {
    padding: 8px;
}
